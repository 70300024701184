@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Mulish", sans-serif;
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  font-display: swap;
  // background-color: black;
}

body.scroll {
  overflow: hidden;
}

.slick-prev:before,
.slick-next:before {
  color: #ffa908 !important;
}

.slick-dots li button:before {
  color: #ffa908 !important;
  font-size: 10px !important;
}
.slick-dots li {
  float: left !important;
}
